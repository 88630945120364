<template lang="pug">
  .policy.wrapper
    .policy_wrap
      h2 {{ $t('legalDocs.appendix1.title') }}
      .policy_updated {{ $t('legalDocs.lastUpdated') }}: {{ updatedLegal(updatedAt, this.$i18n.locale) }}
      ol.policy_main_list
        li(v-html="$t('legalDocs.appendix1.point1')")
        li(v-html="$t('legalDocs.appendix1.point2')")
      p
        router-link(to="/terms") {{ $t('legalDocs.goBackToTermsOfUse')}}
</template>

<script>
import { updatedLegal } from '@/dates';

export default {
  data() {
    return { updatedAt: '2021-06-30' };
  },
  methods: {
    updatedLegal(date, locale) {
      return updatedLegal(date, locale);
    }
  }
};
</script>
